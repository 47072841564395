import React, { FC } from 'react';
import {
  BackgroundContainer,
  BackgroundImage,
} from '@/components/VectorBackground/VectorBackground.styled';

interface IVectorBackgroundProps {
  children: any;
  isSecond?: boolean;
}

const VectorBackground: FC<IVectorBackgroundProps> = ({
  children,
  isSecond,
}) => (
  <BackgroundContainer>
    <BackgroundImage isSecond={isSecond} />
    {children}
  </BackgroundContainer>
);

export default VectorBackground;
