import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  position: relative;
`;

export const BackgroundImage = styled.img<{ isNfts: boolean }>`
  top: ${(props) => (props.isNfts ? -50 : 480)}px;
  left: 0;
  position: absolute;
  z-index: -6;
  width: 100%;
  height: 1600px;
  @media (max-width: 767px) {
    height: 970px;
    top: ${(props) => (props.isNfts ? -250 : 750)}px;
  }
`;

export const ImageBlackoutTop = styled.div<{ isNfts: boolean }>`
  background: linear-gradient(
    180deg,
    rgba(23, 26, 34, 0) 0%,
    rgba(23, 26, 34, 0.4) 30.14%,
    rgba(23, 26, 34, 0.75) 61.71%,
    #171a22 100%
  );
  width: 100%;
  height: 507px;
  position: absolute;
  top: ${(props) => (props.isNfts ? -250 : 280)}px;
  z-index: -5;
  transform: rotate(180deg);
`;

export const ImageBlackoutBottom = styled.div<{ isNfts: boolean }>`
  background: linear-gradient(
    180deg,
    rgba(23, 26, 34, 0) 0%,
    rgba(23, 26, 34, 0.4) 30.14%,
    rgba(23, 26, 34, 0.75) 61.71%,
    #171a22 100%
  );
  width: 100%;
  height: 1512px;
  position: absolute;
  bottom: ${(props) => (props.isNfts ? '-200px' : '0px')};
  z-index: -6;
`;
