import styled from 'styled-components';

interface IExpanded {
  expanded: boolean;
}

export const CommunityBox = styled.div`
  position: fixed;
  right: 30px;
  top: 710px;
  z-index: 10;
  width: ${(props: IExpanded) => (!props.expanded ? 620 : 50)}px;
  height: 50px;
  ${(props: IExpanded) => {
    if (!props.expanded) {
      return 'display: flex';
    }
    return 'display: block';
  }};
  border-radius: 5px;
  background: linear-gradient(#0a0c13, #0a0c13) padding-box,
    linear-gradient(270deg, #5766cc 0%, #9c43a8 100%) border-box;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  align-items: center;
  justify-content: center;
  ${(props: IExpanded) => {
    if (!props.expanded) {
      return null;
    }
    return 'cursor: pointer';
  }};
  transition: all 0.2s ease-out;
  @media (max-width: 768px) {
    width: ${(props: IExpanded) => (!props.expanded ? 303 : 50)}px;
    top: 660px;
  }
  &:hover {
    filter: brightness(1.5);
  }
`;

export const TwitterBox = styled.div`
  position: absolute;
  top: 8.6px;
  left: 16.8px;
  right: 17.8px;
`;
export const DiscordBox = styled.div`
  position: absolute;
  bottom: 11.4px;
  left: 3px;
  top: 27.4px;
`;
export const TelegramBox = styled.div`
  position: absolute;
  right: 3px;
  bottom: 10.6px;
  top: 26.6px;
`;

export const CommunityHeader = styled.div`
  padding: 0 20px;
  ${(props: IExpanded) => {
    if (!props.expanded) {
      return 'display: block';
    }
    return 'display: none';
  }};
  @media (max-width: 767px) {
    display: none;
  }
`;
export const CommunityHeaderTypography = styled.span`
  font-size: 15px;
`;

export const CommunityBtnList = styled.ul`
  display: ${(props: IExpanded) => (!props.expanded ? 'flex' : 'none')};
  list-style: none;
`;

export const CommunityBtnListItem = styled.div`
  padding: 15px 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
  &:hover {
    filter: invert(50%);
  }
`;

export const CommunityBtnImage = styled.img`
  margin-left: ${(props: IExpanded) => (!props.expanded ? 5 : 0)}px;
  width: ${(props: IExpanded) => (!props.expanded ? 5 : 12)};
`;

export const CommunityBtnSubscribersCount = styled.span`
  font-size: 15px;
`;

export const ArrowBox = styled.div`
  cursor: pointer;
`;

export const CommunityArrow = styled.div`
  width: 10px;
  height: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.pink};
  border-right: 1px solid ${(props) => props.theme.colors.pink};
  transform: rotate(45deg);
  margin: 15px 20px;
  ${(props: IExpanded) => {
    if (!props.expanded) {
      return null;
    }
    return 'display: none';
  }};
`;

export const CommunityLink = styled.a``;
