import styled from 'styled-components';
import Telegram from '@/images/CommunityWindow/Telegram.svg';
import Twitter from '@/images/CommunityWindow/Twitter.svg';
import Discord from '@/images/CommunityWindow/Discord.svg';
import Facebook from '@/images/CommunityWindow/Facebook.svg';

export const GameReadyContainer = styled.div<{ hasMargin?: boolean }>`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => {
    if (props.hasMargin) {
      return `
        margin-bottom: 120px;

        @media (max-width: 768px) {
          margin-bottom: 100px;
        }
      `;
    }
  }};
  @media (max-width: 768px) {
    margin-top: 100px;
  }
`;
export const GameReadyWindow = styled.div`
  width: 100%;
  padding: 20px 20px 20px 75px;
  background: rgba(13, 16, 24, 0.6);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  @media (max-width: 1000px) {
    padding: 20px;
  }

  @media (max-width: 768px) {
    max-width: 350px;
    height: 100%;
    padding: 10px 10px 20px 10px;
    flex-direction: column-reverse;
    text-align: center;
  }
`;
export const GameReadyForm = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 10px 20px;
  }
`;
export const GameReadyFormTitle = styled.h2`
  font-size: 50px;
  text-transform: uppercase;
  line-height: 60px;
  text-align: left;
  font-weight: 600;

  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 46px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
`;
export const GameReadyFormDescription = styled.h3`
  font-size: 30px;
  margin-top: 15px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.gray};
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20.25px;
    margin-top: 10px;
  }
`;

export const InputBox = styled.div`
  display: flex;
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;
export const GameReadyFormInput = styled.input`
  color: #000;
  background: #798094;
  border-radius: 5px 0px 0px 5px;
  max-width: 245px;
  width: 100%;
  height: 50px;
  padding: 14px 0 16px 20px;
  font-size: 15px;
  align-items: center;
  height: 100%;
  cursor: default;

  &::placeholder {
    color: #c0c4ce;
  }

  @media (max-width: 768px) {
    max-width: 200px;
  }
`;
export const GameReadyFormSubmitBtn = styled.button`
  max-width: 100px;
  width: 100%;
  height: 50px;
  background: ${(props) => props.theme.colors.pink};
  border-radius: 0px 5px 5px 0px;
  font-size: 15px;
  @media (max-width: 768px) {
    max-width: 90px;
  }
`;
export const GameReadyImageBox = styled.div`
  width: 80%;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const GameReadyImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 735px;
  float: right;
  box-shadow: 0px 10px 50px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
`;
export const AgreementBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;
export const AgreementTitle = styled.span`
  font-size: 15px;
  color: ${(props) => props.theme.colors.gray};
  margin-left: 5px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 17.55px;
  }
`;

export const AgreementLink = styled.a`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    background: ${(props) => props.theme.colors.gray};
    height: 0.5px;
    bottom: 4px;
    left: 0;
    right: 0;
  }
  &:hover {
    &::after {
      width: 0px;
    }
  }
`;

export const Conditions = styled.a`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 82%;
    background: ${(props) => props.theme.colors.gray};
    height: 0.5px;
    bottom: 4px;
    left: 0;
    right: 0;
  }
  &:hover {
    &::after {
      width: 0px;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const CommunityWindow = styled.div`
  position: relative;
  max-width: 1230px;
  width: 100%;
  background: rgba(13, 16, 24, 0.6);
  padding: 50px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  z-index: 3;
  @media (max-width: 768px) {
    max-width: 350px;
    max-height: 3022px;
    padding: 30px 20px 20px 20px;
  }
`;

export const JoinBtn = styled.a`
  width: 75px;
  height: 40px;
  background: ${(props) => props.theme.colors.pink};
  border-radius: 5px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    font-size: 16px;
    filter: brightness(1.15);
  }

  margin-left: 30px;

  &:nth-of-type(2n + 1) {
    margin: 0 30px 0 0;

    @media (max-width: 768px) {
      margin: 0 0 0 auto;
      order: 1;
    }
  }

  @media (max-width: 768px) {
    width: 60px;
    height: 36px;
    margin-left: auto;
  }
`;

export const SocialLink = styled.a``;

export const CommunityTitle = styled.h3`
  font-size: 30px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 30px;
  }
`;
export const SocialTitle = styled.span`
  font-size: 80px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${(props) => props.theme.colors.gray};

  &:hover {
    filter: brightness(1.25);
  }

  @media (max-width: 1100px) {
    font-size: 50px;
  }

  @media (max-width: 768px) {
    font-size: calc(22px + 7 * (100vw - 420px) / 880);
  }

  @media (max-width: 390px) {
    font-size: 24px;
  }
`;
export const SocialCount = styled.span`
  font-size: 80px;
  font-weight: bold;
  margin-left: 15px;

  @media (max-width: 1100px) {
    font-size: 50px;
  }

  @media (max-width: 768px) {
    font-size: calc(22px + 7 * (100vw - 420px) / 880);
  }

  @media (max-width: 390px) {
    font-size: 22px;
  }
`;
export const TelegramBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 315px;
  background: url(${Telegram}) no-repeat;
  background-position: 250px;
  height: 92px;

  @media (max-width: 1100px) {
    padding-left: 215px;
  }

  @media (max-width: 768px) {
    padding: 0;
    padding-left: 5px;
    height: 36px;
    background-position: left;
    background-size: contain;
    & ${SocialCount} {
      margin-left: 10px;
    }
  }

  @media (max-width: 390px) {
    & ${SocialCount} {
      margin-left: 17px;
    }
  }
`;

export const SocialWrapper = styled.div``;

export const TwitterBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 415px;
  background: url(${Twitter}) no-repeat;
  background-position: 990px;
  height: 108px;

  @media (max-width: 1100px) {
    background-position: 600px;
    padding-left: 315px;
  }

  @media (max-width: 768px) {
    padding: 0;
    padding-left: 5px;
    height: 36px;
    background-position: 72px;
    background-size: contain;
    margin-top: 20px;
    & ${SocialCount} {
      margin-left: 37px;
    }
  }
`;

export const DiscordBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 210px;
  height: 92px;
  background: url(${Discord}) no-repeat;
  background-position: 162px;

  @media (max-width: 1100px) {
    padding-left: 150px;
  }

  @media (max-width: 768px) {
    padding: 0;
    padding-left: 5px;
    height: 36px;
    background-position: left;
    background-size: contain;
    margin-top: 20px;
    & ${SocialCount} {
      margin-left: 37px;
    }
  }
`;

export const FacebookBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 415px;
  background: url(${Facebook}) no-repeat;
  background-position: 990px;
  height: 108px;

  @media (max-width: 1100px) {
    background-position: 600px;
    padding-left: 315px;
  }

  @media (max-width: 768px) {
    padding: 0;
    padding-left: 5px;
    height: 36px;
    background-position: 72px;
    background-size: contain;
    margin-top: 20px;
    & ${SocialCount} {
      margin-left: 37px;
    }
  }
`;

export const CommunityLink = styled.a``;

export const InputStatus = styled.span`
  font-size: 12px;
  margin-top: 10px;
`;
