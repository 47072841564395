import React, { FC } from 'react';
import GameReadyBackgroundImage from '@/images/Backgrounds/GameReadyBackground.png';
import GameReadySmall from '@/images/GameReady/GameReadySmall1.png';
import {
  BackgroundContainer,
  BackgroundImage,
  ImageBlackoutBottom,
  ImageBlackoutTop,
} from '@/components/GameReadyBackground/GameReadyBackground.styled';
import { useIsMobile } from '@/hooks/useMobile';

interface IGameReadyBackgroundProps {
  isNfts?: boolean;
  children: any;
}

const HelicopterBackground: FC<IGameReadyBackgroundProps> = ({
  children,
  isNfts,
}) => {
  const isMobile = useIsMobile();

  return (
    <BackgroundContainer>
      <BackgroundImage
        isNfts={isNfts}
        src={isMobile ? GameReadySmall : GameReadyBackgroundImage}
        alt=""
      />
      <ImageBlackoutTop isNfts={isNfts} />
      <ImageBlackoutBottom isNfts={isNfts} />
      {children}
    </BackgroundContainer>
  );
};

export default HelicopterBackground;
