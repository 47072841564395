import styled from 'styled-components';
import VectorBackgroundImage from '@/images/Backgrounds/BackgroundVector.svg';
import VectorBackgroundImageSecond from '@/images/Backgrounds/BackgroundVectorSecond.svg';

export const BackgroundContainer = styled.div`
  position: relative;
`;

export const BackgroundImage = styled.div<{ isSecond?: boolean }>`
  background: url(${(props) =>
    props.isSecond ? VectorBackgroundImageSecond : VectorBackgroundImage});
  width: 100%;
  height: 1000px;
  background-size: cover;
  position: absolute;
  background-position: right;
  visibility: hidden;
  top: ${(props) => (props.isSecond ? 0 : -150)}px;
  @media (max-width: 767px) {
    top: 160px;
  }
  @media (max-width: 2200px) {
    visibility: visible;
    z-index: -1;
  }
`;
