import React, { useEffect, useState } from 'react';
import Container from '@/components/Container';
import {
  AgreementBox,
  AgreementLink,
  AgreementTitle,
  CommunityTitle,
  CommunityWindow,
  Conditions,
  DiscordBox,
  GameReadyContainer,
  GameReadyForm,
  GameReadyFormDescription,
  GameReadyFormInput,
  GameReadyFormTitle,
  GameReadyImage,
  GameReadyImageBox,
  GameReadyWindow,
  InputBox,
  JoinBtn,
  SocialCount,
  SocialTitle,
  TelegramBox,
  TwitterBox,
  FacebookBox,
  InputStatus,
} from '@/components/GameReady/GameReady.styled';
import CustomCheckBox from '@/components/UI/CustomCheckBox/CustomCheckBox';
import GameImage from '@/images/GameReady/GameReady.png';
import { CommunityLink } from '@/components/Community/Community.styled';
import { SocialLinks, ExternalLinks } from '@/utils/constants';
import { numberWithCommas } from '@/utils/numbers';
import { Button, ButtonStyleTypes, BorderType } from '@/components/UI';
import { subscribe } from '@/utils/api';
import { useAppSelector } from '@/hooks/redux';

const GameReady = ({ hasMargin = false }) => {
  const { socialInfo } = useAppSelector((state) => state.socialReducer);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [emailState, setEmailState] = useState({
    value: '',
    errorMessage: null,
    successMessage: '',
  });

  useEffect(() => {
    const lastAtPos = emailState.value.lastIndexOf('@');
    const lastDotPos = emailState.value.lastIndexOf('.');

    if (!isTermsAccepted) {
      setEmailState({ ...emailState, errorMessage: 'Accept the terms of use' });
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        emailState.value.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        emailState.value.length - lastDotPos > 2
      )
    ) {
      setEmailState({ ...emailState, errorMessage: 'Email is not valid' });
    } else {
      setEmailState({ ...emailState, errorMessage: '' });
    }
  }, [emailState.value, isTermsAccepted]);

  const onSubmit = () => {
    setIsSubmitPressed(true);

    if (isTermsAccepted && emailState.errorMessage === '') {
      subscribe(emailState.value).then(() => {
        setEmailState({
          ...emailState,
          successMessage: 'Successfully subscribed',
        });
      });
    }
  };

  return (
    <Container>
      <GameReadyContainer hasMargin={hasMargin}>
        <CommunityWindow>
          <CommunityTitle>Join our aviator community</CommunityTitle>
          <TelegramBox>
            <CommunityLink target="_blank" href={SocialLinks.TELEGRAM}>
              <SocialTitle>Telegram</SocialTitle>
            </CommunityLink>
            <SocialCount>
              {socialInfo.telegram
                ? numberWithCommas(socialInfo.telegram)
                : '...'}
            </SocialCount>
            <JoinBtn target="_blank" href={SocialLinks.TELEGRAM}>
              Join
            </JoinBtn>
          </TelegramBox>
          <TwitterBox>
            <JoinBtn target="_blank" href={SocialLinks.TWITTER}>
              Join
            </JoinBtn>
            <CommunityLink target="_blank" href={SocialLinks.TWITTER}>
              <SocialTitle>Twitter</SocialTitle>
            </CommunityLink>
            <SocialCount>
              {socialInfo.twitter
                ? numberWithCommas(socialInfo.twitter)
                : '...'}
            </SocialCount>
          </TwitterBox>
          <DiscordBox>
            <CommunityLink target="_blank" href={SocialLinks.DISCORD}>
              <SocialTitle>Discord</SocialTitle>
            </CommunityLink>
            <SocialCount>
              {socialInfo.discord
                ? numberWithCommas(socialInfo.discord)
                : '...'}
            </SocialCount>
            <JoinBtn target="_blank" href={SocialLinks.DISCORD}>
              Join
            </JoinBtn>
          </DiscordBox>
          <FacebookBox>
            <JoinBtn target="_blank" href={SocialLinks.FACEBOOK}>
              Join
            </JoinBtn>
            <CommunityLink target="_blank" href={SocialLinks.FACEBOOK}>
              <SocialTitle>Facebook</SocialTitle>
            </CommunityLink>
            <SocialCount>
              {socialInfo.facebook
                ? numberWithCommas(socialInfo.facebook)
                : '...'}
            </SocialCount>
          </FacebookBox>
        </CommunityWindow>
      </GameReadyContainer>
    </Container>
  );
};

export default GameReady;
